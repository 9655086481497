<template>
  <b-card class="shop-find-agents">
    <div
      v-if="orderDetails"
      class="row mx-auto justify-content-center mb-1"
    >
      <div class="col-md-3 rounded-left border">
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/accept.png')" />
          </template>
          <h6 class="text-dark">
            {{ orderDetails.store_order_invoice_id }}
          </h6>
        </b-media>
      </div>
      <div
        v-if="orderDetails.user"
        class="col-md-3 border"
        :class="{ 'rounded-right': !assignedAgent }"
      >
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/map.png')" />
          </template>
          <h6 class="text-dark">
            {{ orderDetails.user.first_name }} {{ orderDetails.user.last_name }}
          </h6>
          <p
            v-if="orderDetails.delivery"
            class="mb-0 text-dark"
          >
            {{ orderDetails.delivery.map_address }}
          </p>
        </b-media>
      </div>
      <div
        v-if="assignedAgent"
        class="col-md-3 rounded-right border"
      >
        <b-media class="pb-50 pt-50 align-items-center">
          <template #aside>
            <b-avatar :src="require('@/assets/images/app-icons/express-delivery.png')" />
          </template>
          <h6 class="text-dark">
            {{ assignedAgent.first_name }} {{ assignedAgent.last_name }}
          </h6>
          <p class="mb-0 text-dark">
            {{ assignedAgent.mobile }}
          </p>
        </b-media>
      </div>
    </div>
    <b-row>
      <b-col
        v-if="orderId"
        cols="12"
      >
        <good-data-table
          ref="findAgents"
          :mode="'local'"
          :button-label="''"
          :columns="storeTypeColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()"
          @table-response="changeParams"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BMedia, BAvatar } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useStoreUi } from './useStore'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BCard,
    BMedia,
    BAvatar,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    orderId: {
      typeof: String,
      default: '',
    },
  },
  data() {
    return {
      assignedAgent: null,
      editStoreType: null,
      activeCity: {},
      showStoreTypesModal: false,
      orderDetails: null,
      allAgents: [],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    storeTypeColumns() {
      return [
        {
          label: 'Profile',
          field: 'picture',
          preventExport: true,
          useHtml: true,
          excelHtml: props => window.SuperApp.helper.image(props.picture),
          renderer: props => (props.picture ? `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="height: 35px;" />` : 'NA'),
        },
        {
          label: 'Agent Name',
          field: 'first_name',
          rField: 'first_name_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => `${props.first_name} ${props.last_name}`,
        },
        {
          label: 'Pickup ETA and Distance',
          field: 'pickup_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.pickup_duration} Mins ${props.pickup_distance} km`,
          renderer: props => `<b>In ${props.pickup_duration} Mins</b> <br />${props.pickup_distance} km`,
        },
        {
          label: 'Delivery ETA and Distance',
          field: 'delivery_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.delivery_duration} Mins ${props.delivery_distance} km`,
          renderer: props => `<b>In ${props.delivery_duration} Mins</b> <br />${props.delivery_distance} km`,
        },
        {
          label: 'Total ETA and Distance',
          field: 'total_duration_text',
          useHtml: true,
          useResolver: true,
          textRenderer: props => `In ${props.total_duration} Mins ${props.total_distance} km`,
          renderer: props => `<b>In ${props.total_duration} Mins</b> <br />${props.total_distance} km`,
        },
        {
          label: 'Availability',
          field: 'activation_status_text',
          useHtml: true,
          useResolver: true,
          // eslint-disable-next-line no-nested-ternary
          textRenderer: props => `${props.activation_status && !props.incomplete_orders_count ? 'Available' : props.incomplete_orders_count ? `Busy (${props.incomplete_orders_count})` : 'Not Available'}`,
          renderer: props => `<span class="${props.activation_status && !props.incomplete_orders_count ? 'text-success' : 'text-danger'}">${
            // eslint-disable-next-line no-nested-ternary
              props.activation_status && !props.incomplete_orders_count ? 'Available' : props.incomplete_orders_count ? `Busy (${props.incomplete_orders_count})` : 'Not Available'
            }</span>`,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          isButtons: true,
          buttons: [
            {
              text: 'Show on Map',
              variant: 'success',
              action: props => {
                this.$emit('show-on-map', props)
              },
            },
            {
              renderVariant: props => (this.orderDetails && this.orderDetails.agent_id === props.id ? 'success' : 'primary'),
              renderButtonText: props => (this.orderDetails && this.orderDetails.agent_id === props.id ? 'Assigned' : 'Assign'),
              action: props => {
                this.assignOrder(props)
              },
            },
          ],
        },
      ]
    },
  },
  watch: {
    orderId: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.getOrderDetailsById()
          if (this.$refs.findAgents) this.$refs.findAgents.loadItems()
        }
      },
    },
  },
  mounted() {
    if (this.$route.params.orderId) {
      this.orderId = this.$route.params.orderId
    }
  },
  methods: {
    setAgentInfo() {
      if (this.orderDetails) {
        const isAgentExist = this.allAgents.find(x => x.id === this.orderDetails.agent_id)
        if (isAgentExist) {
          this.assignedAgent = isAgentExist
        }
      }
    },
    changeParams({ responseData }) {
      showLoader()
      this.allAgents = responseData.data
      this.setAgentInfo()
      setTimeout(() => {
        this.$refs.findAgents.rows = this.$refs.findAgents.rows.filter(x => x.is_online === 1)
        hideLoader()
      }, 150)
    },
    getOrderDetailsById() {
      const { getOrderDetailsById } = useStoreUi()

      getOrderDetailsById(this.orderId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderDetails = data.responseData
            this.setAgentInfo()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getApiEndpoint() {
      return `/shop/agents/find/agents?order_id=${this.orderId}`
    },
    assignOrder(payload) {
      const { shopAssignAgentToOrder } = useStoreUi()
      const formData = new FormData()

      formData.append('id', this.orderId)
      formData.append('admin_service', 'ORDER')
      formData.append('agent_id', payload.id)
      formData.append('comments', '')

      showLoader()
      shopAssignAgentToOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.orderDetails.user_id,
              orderId: this.orderId,
              service: 'ORDER',
            })
            eventBus.$emit('provider-sync-app', {
              agentId: payload.id,
              showIncomingRequest: true,
            })
            this.getOrderDetailsById()
            this.$refs.findAgents.loadItems()
            this.$emit('create-order', data.responseData)
            eventBus.$emit('refresh-store-accept-grid')
            eventBus.$emit('refresh-store-accept-bars')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.shop-find-agents tr td.vgt-left-align:last-child {
  width: 300px;
}

.shop-find-agents .vue-good-datatable-view .card-header {
  padding: 0;
}

.shop-find-agents tr td.vgt-left-align:last-child span button:first-child {
  margin-bottom: 7px;
}
</style>
